body {
  margin: 0;
}

p {
  margin: 0.2em 0;
}

.mz-chart {
  background-color: #eeeeee;
  font-family: "Graphik LC", sans-serif;
}

.mz-chart__header {
  font-size: 30px;
  font-weight: 700;
}

.mz-chart__description {
  font-size: 12px;
  opacity: 0.5;
}

.mz-chart__bar {
  opacity: 0.9;
}

.mz-chart__tooltip {
  z-index: 999;
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.26);
  box-sizing: border-box;
  min-height: 50px;
  padding: 15px 20px 15px 20px;
  pointer-events: auto;
  max-width: 280px;
  font-family: "Graphik LC", sans-serif;
  width: max-content;
}

.mz-chart__tooltip-header {
  font-weight: 700;
}

.mz-chart__refresh {
  cursor: pointer;
}

.mz-chart__label-horizontal {
  font-size: 12px;
  text-anchor: middle;
}
.mz-chart__label-vertical {
  font-size: 12px;
  text-anchor: end;
  dominant-baseline: middle;
}
.mz-chart__label-vertical:first-child {
  dominant-baseline: hanging;
}
.mz-chart__label-vertical:last-child {
  dominant-baseline: auto;
}

.mz-chart__grid-line {
  stroke: black;
  stroke-width: 1;
  opacity: 0.2;
}

.mz-chart__median-line line {
  stroke: black;
  stroke-width: 2;
}

.mz-chart__median-label {
  text-anchor: start;
  dominant-baseline: auto;
  font-weight: 700;
}
