body {
  margin: 0;
}

p {
  margin: .2em 0;
}

.mz-chart {
  background-color: #eee;
  font-family: Graphik LC, sans-serif;
}

.mz-chart__header {
  font-size: 30px;
  font-weight: 700;
}

.mz-chart__description {
  opacity: .5;
  font-size: 12px;
}

.mz-chart__bar {
  opacity: .9;
}

.mz-chart__tooltip {
  z-index: 999;
  box-sizing: border-box;
  min-height: 50px;
  pointer-events: auto;
  max-width: 280px;
  width: max-content;
  background-color: #fff;
  padding: 15px 20px;
  font-family: Graphik LC, sans-serif;
  display: none;
  position: absolute;
  box-shadow: 0 0 15px #00000042;
}

.mz-chart__tooltip-header {
  font-weight: 700;
}

.mz-chart__refresh {
  cursor: pointer;
}

.mz-chart__label-horizontal {
  text-anchor: middle;
  font-size: 12px;
}

.mz-chart__label-vertical {
  text-anchor: end;
  dominant-baseline: middle;
  font-size: 12px;
}

.mz-chart__label-vertical:first-child {
  dominant-baseline: hanging;
}

.mz-chart__label-vertical:last-child {
  dominant-baseline: auto;
}

.mz-chart__grid-line {
  stroke: #000;
  stroke-width: 1px;
  opacity: .2;
}

.mz-chart__median-line line {
  stroke: #000;
  stroke-width: 2px;
}

.mz-chart__median-label {
  text-anchor: start;
  dominant-baseline: auto;
  font-weight: 700;
}

/*# sourceMappingURL=index.59f7cdeb.css.map */
